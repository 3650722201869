import PropTypes from "prop-types";
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import React from "react";
import _ from "lodash";
import {globalHistory} from "@reach/router"
import AvatarMenu from "./Avatar";
import Button from "@material-ui/core/Button";

const Header = props => {
    let {siteTitle, showAvatar, toggleDrawer} = props;
    const path = globalHistory.location.pathname;
    const background = process.env.BACKGROUND;

    return <div
            style={{
                background: process.env.BACKGROUND,
                marginBottom: `1.45rem`,
                height: 64,
                borderBottom: "1px solid #EEE",
                width: 'max-content',
                minWidth: '100%'
            }}>
            <div style={{
                    margin: `0 auto`,
                    padding: `0.4rem 0 0 0`
                }}>
                <>
                    {props.showSidebar && <IconButton
                        style={{
                            marginBottom: '10px',
                            marginRight: '10px',
                            color:background==='black'?'white':'black',
                            display: showAvatar ? 'inline-block' : 'none',
                            position: 'sticky',
                            left: '20px'
                        }}
 
                        aria-label="Open drawer"
                        onClick={toggleDrawer('left', true)}
                    >
                        <svg className="MuiSvgIcon-root menuIcon" focusable="false" viewBox="0 0 24 24" aria-hidden="true" style={{width: '28px', color: 'rgb(21, 146, 205)'}}><path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"></path></svg>
                    </IconButton>
                    }
                    <div style={{zIndex: 1001, position: 'sticky',right: '20px',float: "right", width: 45,
                            display: showAvatar ? 'inline-block' : 'none',
                    }}>
                    <AvatarMenu />
                    </div>
                    {props.children}
                </>

            </div>
        </div>
};

Header.propTypes = {
    siteTitle: PropTypes.string
};

Header.defaultProps = {
    siteTitle: ``
};

export default Header;
