import { navigate } from "gatsby";
import axios from 'axios';
import md5 from "md5";
import _ from "lodash";

export async function login(email, password) {
    const { data } = await axios.post(
        process.env.SERVER_ENDPOINT + "/verifyUser",
        {
            email,
            password: md5(password),
        }
    );
    console.log(data);

    if (data && typeof window === "object") {
        window.localStorage.setItem("user", JSON.stringify(data));
        return true;
    } else {
        return false;
    }
}


export function checkAuth(role) {
    if (typeof window === "object") {
        const user = JSON.parse(window.localStorage.getItem("user"));
        if (!user) {
            console.log('should navigate to /login - no user');
            navigate("/login");
            return [];
        }
        if (!user.token) {
            console.log('should navigate to /login - no token');
            window.localStorage.removeItem("user");
            navigate("/login");
            return [];
        }
        if (!user.roles) {
            return [];
        }
        const roles = user.roles.split(',');
        if (!role) {
            console.log('no master role');
            return roles;
        }
        if (!roles.includes(role)) {
            console.log('should navigate to /404 - no role');
            navigate("/404");
            return [];
        }
        return roles;
    }
    return [];
}

export function logout() {
    if (typeof window === "object") {
        window.localStorage.removeItem("user");
        navigate("/login");
    }
}

export function getUserEmail() {
    let email = '';
    if (typeof window === "object") {
        const user = JSON.parse(window.localStorage.getItem("user"));
        if (user)
            email = user.email;
    }
    return email;
}



export const auth = {
    get : async (url, options={}) => {
        const user = JSON.parse(window.localStorage.getItem("user"));
        const auth = {
            headers: {
              Authorization: `Bearer ${user.token}`
            }
          }
        return await axios.get(url,{...options, ...auth});
    },
    post: async (url, body, options={})=>{
        const user = JSON.parse(window.localStorage.getItem("user"));
        const auth = {
            headers: {
              Authorization: `Bearer ${user.token}`
            }
          }
        return await axios.post(url, body, {...options, ...auth});
    },
    put: async (url, body, options={})=>{
        const user = JSON.parse(window.localStorage.getItem("user"));
        const auth = {
            headers: {
              Authorization: `Bearer ${user.token}`
            }
          }
        return await axios.put(url, body, {...options, ...auth});
    },
    delete: async (url, body)=>{
        const user = JSON.parse(window.localStorage.getItem("user"));
        const auth = {
            headers: {
              Authorization: `Bearer ${user.token}`
            },
            data: body
          }
        return await axios.delete(url,  {...auth});
    }
} 

