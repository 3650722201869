import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import { lightGreen } from "@material-ui/core/colors";

import Header from "./header";
import Drawer from "./sidebar";

const path = typeof window === "object" ? window.location.host : "default";

const theme = createMuiTheme({
  pallete: {
    type: "dark",
    // primary: { main: purple[500] },
    primary: lightGreen,
    secondary: lightGreen
    // secondary: { main: "#11cb5f" },
  }
});

const Layout = ({ children, showAvatar, showSidebar }) => {
    const [state, setState] = React.useState({
        left: false,
        loading: true
    });
    const toggleDrawer = (side, open) => event => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [side]: open });
    };
    return <StaticQuery
        query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
        render={data => (
            <>
                <ThemeProvider theme={theme}>
                    <Header
                        siteTitle={data.site.siteMetadata.title}
                        showAvatar={showAvatar}
                        toggleDrawer={toggleDrawer}
                        showSidebar={(showSidebar == undefined || showSidebar)}
                    >
                        {(showSidebar == undefined || showSidebar) && <Drawer left={state.left} toggleDrawer={toggleDrawer}/>}
                        <div
                            style={{
                                margin: `0 auto`,
                                // maxWidth: 1260,

                                backgroundColor: "white",
                                borderRadius: 7
                            }}
                        >
                            {children}
                        </div>
                        <footer className="footer">
                            © {new Date().getFullYear()}, {process.env.ORGANIZATION}
                        </footer>
                    </Header>

                </ThemeProvider>
            </>
        )}
    />
};

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

Layout.defaultProps = {
  showAvatar: true
};

export default Layout;
