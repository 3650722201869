import React, {Component} from "react"
import Gravatar from "react-gravatar";
import {navigate} from "gatsby";

export default class AvatarMenu extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isOpen: false,
        }

        this.toggleMenu = this.toggleMenu.bind(this)
        this.getUserEmail = this.getUserEmail.bind(this)
        this.logout = this.logout.bind(this)
    }

    logout() {
        if (typeof window === "object") {
            window.localStorage.removeItem("user");
            navigate("/login");
        }
    }

    getUserEmail() {
        let email = '';
        if (typeof window === "object") {
            const user = JSON.parse(window.localStorage.getItem("user"));
            if (user)
                email = user.email;
        }
        return email;
    }

    toggleMenu() {
        const {isOpen} = this.state;
        this.setState({isOpen: !isOpen})
    }

    render() {
        const li = {
            listStyle: "none",
            padding: 4,
            color: "#333",
            fontFamily: "Lato",
        }
        return (
            <>
            <div>
                <Gravatar
                    email={this.getUserEmail()}
                    default="mp"
                    style={{zIndex: 1001, cursor: "pointer", borderRadius: 30, position: 'relative', top:'6px'}}
                    size={40}
                    onClick={this.toggleMenu}
                />

            </div>
                            {this.state.isOpen && (
                                <div
                                    style={{
                                        backgroundColor: "white",
                                        borderRadius: 5,
                                        boxShadow: "0 5px 5px #CCC",
                                        marginTop: -10,
                                        marginBottom: 10,
                                        width: 200,
                                        padding: 10,
                                        right: 0,
                                        position: "absolute",
                                        cursor: 'pointer',
                                        zIndex: 1502,
                                        paddingBottom:1
                                    }}
                                >
                                    <a href={null} onClick={this.logout}>
                                        <li style={li}>Logout</li>
                                    </a>
                                </div>
                            )}
                            </>
        )
    }
}
