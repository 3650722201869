import React from 'react';
import Drawer from '@material-ui/core/Drawer/index';
import Button from '@material-ui/core/Button/index';
import List from '@material-ui/core/List/index';
import Divider from '@material-ui/core/Divider/index';
import ListItem from '@material-ui/core/ListItem/index';
import ListItemIcon from '@material-ui/core/ListItemIcon/index';
import ListItemText from '@material-ui/core/ListItemText/index';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import FormLabel from '@material-ui/core/FormLabel';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import DialogTitle from '@material-ui/core/DialogTitle';
import DashboardIcon from '@material-ui/icons/Dashboard';
import DnsIcon from '@material-ui/icons/Dns';
import ShopIcon from '@material-ui/icons/Shop';
import { Link } from "gatsby";
import ScheduleIcon from '@material-ui/icons/Schedule';
import { ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { checkAuth } from '../helpers/auth';

export default function TemporaryDrawer({ left, toggleDrawer }) {
    let currentLocation = '';
    if (typeof (document) !== 'undefined') {
        currentLocation = document.location.pathname;
    } else {
        currentLocation = '';
    }

    const roles = checkAuth();

    const disabled_features = process.env.DISABLED_FEATURES.split(",");




    const mediaPlanner = roles.includes('media-planner') && <>
        {
            disabled_features.indexOf("MEDIA_PLANNER") === -1 &&
            <Link to="/media-planner/">
                <ListItem className={currentLocation === '/media-planner/' ? 'active' : ''} button key="Media Planner">
                    {/* <ListItemIcon><DashboardIcon/></ListItemIcon> */}
                    <ListItemIcon>
                        <img alt="" src="/icons/svg/mediabuys.svg" />
                    </ListItemIcon>
                    <ListItemText primary="Media Planner" />
                </ListItem>
            </Link>
        }

        {
            disabled_features.indexOf("UPDATE_MEDIA_SPENT") === -1 &&
            <Link to="/media-planner/media-spend">
                <ListItem className={currentLocation === '/media-planner/media-spent' ? 'active' : ''} button key="Update Media Spent">
                    <ListItemIcon>
                        <img alt="" src="/icons/svg/mediaspent.svg" />
                    </ListItemIcon>
                    <ListItemText primary="Update Media Spend" />
                </ListItem>
            </Link>
        }
        <Link to="/media-planner/landing-page-manager">
            <ListItem className={currentLocation === '/media-planner/landing-page-manager' ? 'active' : ''} button >
                <ListItemIcon>
                    <img alt="" src="/icons/svg/landingpagemanager.svg" />
                </ListItemIcon>
                <ListItemText primary="Landing Page Manager" />
            </ListItem>
        </Link>
        <Link to="/media-planner/form-builder">
            <ListItem className={currentLocation === '/media-planner/form-builder' ? 'active' : ''} button>
                <ListItemIcon>
                    <img alt="" src="/icons/svg/formwidgetbuilder.svg" />
                </ListItemIcon>
                <ListItemText primary="Form & Widget Builder" />
            </ListItem>
        </Link>

        {
            disabled_features.indexOf("LEAD_LOOKUP") === -1 &&
            <Link to="/media-planner/lookup">
                <ListItem className={currentLocation === '/media-planner/lookup' ? 'active' : ''} button key="Lead Lookup">
                    {/* <ListItemIcon><DnsIcon/></ListItemIcon> */}
                    <ListItemIcon>
                        <img alt="" src="/icons/svg/leadlookup.svg" />
                    </ListItemIcon>
                    <ListItemText primary="Lead Lookup" />
                </ListItem>
            </Link>
        }

        {
            disabled_features.indexOf("CLICK_FULFILLMENT") === -1 &&
            <Link to="/media-planner/media-orders">
                <ListItem className={currentLocation === '/media-planner/media-orders' ? 'active' : ''} button key="Media Buyer">
                    {/* <ListItemIcon><ShopIcon/></ListItemIcon> */}
                    <ListItemIcon>
                        <img alt="" src="/icons/svg/clickfullfillment.svg" />
                    </ListItemIcon>
                    <ListItemText primary="Click Fulfillment" />
                </ListItem>
            </Link>
        }

        <ExpansionPanel className="filtering__menu-expand">
            <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="menu-filter-content"
                id="menu-filter-content"
            >
                <ListItemIcon>
                    <img alt="" style={{
                        top: '0px',
                        position: 'relative',
                        left: '1px'
                    }} src="/icons/svg/reports.svg" />
                </ListItemIcon>
                <Typography>
                    Reporting
                        </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                {
                    disabled_features.indexOf("Webinar") === -1 &&
                    <Link to="/media-planner/webinar">
                        <ListItem className={currentLocation === '/media-planner/webinar' ? 'active' : ''} button key="Webinar">
                            {/* <ListItemIcon><DnsIcon/></ListItemIcon> */}
                            <ListItemText primary="Webinar Registration" />
                        </ListItem>
                    </Link>
                }

                {
                    disabled_features.indexOf("LTV") === -1 &&
                    <Link to="/media-planner/ltv">
                        <ListItem className={currentLocation === '/media-planner/ltv' ? 'active' : ''} button key="LTV">
                            {/* <ListItemIcon><DnsIcon/></ListItemIcon> */}
                            <ListItemText primary="LTV" />
                        </ListItem>
                    </Link>
                }

                {
                    disabled_features.indexOf("SALES") === -1 &&
                    <Link to="/media-planner/sales">
                        <ListItem className={currentLocation === '/media-planner/sales' ? 'active' : ''} button key="Sales">
                            {/* <ListItemIcon><DnsIcon/></ListItemIcon> */}
                            <ListItemText primary="Sales by Time Period" />
                        </ListItem>
                    </Link>
                }

                {
                    disabled_features.indexOf("DASHBOARD") === -1 &&
                    <Link to="/media-planner/dashboard">
                        <ListItem className={currentLocation === '/media-planner/dashboard' ? 'active' : ''} button key="dashboard">
                            {/* <ListItemIcon><DnsIcon/></ListItemIcon> */}
                            <ListItemText primary="Dashboard" />
                        </ListItem>
                    </Link>
                }

                {
                    disabled_features.indexOf("CLIENT_SUBMISSION") === -1 &&
                    <Link to="/media-planner/internal-activity/cto">
                        <ListItem className={{ active: currentLocation === '/media-planner/internal-activity/cto' || currentLocation === '/media-planner/internal-activity/coreg' || currentLocation === '/media-planner/internal-activity/submissions' ? 'active' : '' }} button key="internalActivity">
                            {/* <ListItemIcon><DnsIcon/></ListItemIcon> */}
                            <ListItemText primary="Internal Activity" />
                        </ListItem>
                    </Link>
                }

                {
                    disabled_features.indexOf("LEADS") === -1 &&
                    <Link to="/media-planner/leads">
                        <ListItem className={currentLocation === '/media-planner/leads' ? 'active' : ''} button key="leads">
                            {/* <ListItemIcon><DnsIcon/></ListItemIcon> */}
                            <ListItemText primary="Leads by Day/Week" />
                        </ListItem>
                    </Link>
                }

                {
                    disabled_features.indexOf("LINKS_CLICKED") === -1 &&
                    <Link to="/media-planner/links-clicked">
                        <ListItem className={currentLocation === '/media-planner/links-clicked' ? 'active' : ''} button key="links-clicked">
                            {/* <ListItemIcon><DnsIcon/></ListItemIcon> */}
                            <ListItemText primary="Links Clicked" />
                        </ListItem>
                    </Link>
                }


                {
                    disabled_features.indexOf("LEADGEN") === -1 &&
                    <Link to="/media-planner/leadgen">
                        <ListItem className={currentLocation === '/media-planner/leadgen' ? 'active' : ''} button key="leadgen">
                            {/* <ListItemIcon><DnsIcon/></ListItemIcon> */}
                            <ListItemText primary="Lead Gen" />
                        </ListItem>
                    </Link>
                }
                <Link to="/media-planner/submissions">
                    <ListItem className={currentLocation === '/media-planner/submissions' ? 'active' : ''} button key="submissions">
                        {/* <ListItemIcon><DnsIcon/></ListItemIcon> */}
                        <ListItemText primary="Submissions" />
                    </ListItem>
                </Link>

                {
                    disabled_features.indexOf("TRANSACTIONS") === -1 &&
                    <>
                        <Link to="/media-planner/transactions">
                            <ListItem className={currentLocation === '/media-planner/transactions' ? 'active' : ''} button key="transactions">
                                {/* <ListItemIcon><DnsIcon/></ListItemIcon> */}
                                <ListItemText primary="Transactions" />
                            </ListItem>
                        </Link>
                        <Link to="/media-planner/product-sales">
                            <ListItem className={currentLocation === '/media-planner/product-sales' ? 'active' : ''} button key="transactions">
                                {/* <ListItemIcon><DnsIcon/></ListItemIcon> */}
                                <ListItemText primary="Product Sales by Day" />
                            </ListItem>
                        </Link>
                    </>
                }

                {
                    process.env.ORGANIZATION === 'Traders Agency' && <Link to="/media-planner/sales-after-lead">
                        <ListItem className={currentLocation === '/media-planner/sales-after-lead' ? 'active' : ''} button key="sales-after-lead">
                            {/* <ListItemIcon><DnsIcon/></ListItemIcon> */}
                            <ListItemText primary="Submissions by GCLID" />
                        </ListItem>
                    </Link>
                }

                {
                    process.env.ORGANIZATION === 'Traders Agency' && <Link to="/media-planner/refund-rate">
                        <ListItem className={currentLocation === '/media-planner/refund-rate' ? 'active' : ''} button key="refund-rate">
                            {/* <ListItemIcon><DnsIcon/></ListItemIcon> */}
                            <ListItemText primary="Refund Rate" />
                        </ListItem>
                    </Link>
                }

                {
                    process.env.ORGANIZATION === 'Traders Agency' && <Link to="/media-planner/fe-unique-summary">
                        <ListItem className={currentLocation === '/media-planner/fe-unique-summary' ? 'active' : ''} button key="fe-unique-summary">
                            {/* <ListItemIcon><DnsIcon/></ListItemIcon> */}
                            <ListItemText primary="FE Unique Summary" />
                        </ListItem>
                    </Link>
                }

                <Link to="/media-planner/report-scheduler">
                    <ListItem className={currentLocation === '/media-planner/report-scheduler' ? 'active' : ''} button key="report-scheduler">
                        {/* <ListItemIcon><DnsIcon/></ListItemIcon> */}
                        <ListItemText primary="Report scheduler" />
                    </ListItem>
                </Link>
            </ExpansionPanelDetails>
        </ExpansionPanel>
    </>;

    const brainyConcepts = roles.includes('brainy-concepts-licensing-portal') &&
        <Link to="/licensing-portal" style={{marginTop:'50px', borderTop:'1px dashed #aaa', display: roles.length > 1 ? 'block': 'inline'}}>
            <ListItem className={currentLocation === '/licensing-portal' ? 'active' : ''} button key="Brainy Concepts Licensing Portal">
                {/* <ListItemIcon><ShopIcon/></ListItemIcon> */}
                <ListItemIcon>
                    <img alt="" src="/icons/svg/clickfullfillment.svg" />
                </ListItemIcon>
                <ListItemText primary="Brainy Concepts Licensing" />
            </ListItem>
        </Link>;




    return (
        <Drawer open={left} onClose={toggleDrawer('left', false)}>
            <DialogTitle disableTypography style={{
                display: 'flex',
                justifyContent: 'flex-end',
                padding: '0px',
                width: '250px',
                borderBottom: '1px solid #E8E8E8'
            }}>
                <a href="/media-planner">
                    <img
                        alt={process.env.ORGANIZATION}
                        style={{ maxWidth: 160, margin: '10px 10px 10px 0' }}
                        src={process.env.LOGO}
                    />
                </a>
                <IconButton onClick={toggleDrawer('left', false)}>
                    <img alt="" style={{ marginBottom: 0 }} src="/icons/svg/sidebar.svg" />
                </IconButton>
            </DialogTitle>
            <div
                style={{ height: '100%' }}
                role="presentation"
                onKeyDown={toggleDrawer('left', false)}
            >
                <List className="sidebar">
                    {mediaPlanner}
                    {brainyConcepts}
                </List>
            </div>
        </Drawer>
    );
}
